<template>
<div class="container">
	<div>
		<p class="strong" style="text-align:center">叁零荟隐私保护条款</p>
		<p>日期：2023年11月24日</p>
		<p>“叁零荟”平台是由上海叁個零科技有限公司（注册地址为：上海市金山区枫泾镇曹黎路38弄19号8091室，联系地址为：上海市虹口区中山北一路1230号B座4楼）、及其关联公司（以下简称“叁零荟”或“我们”）通过微信小程序或App客户端向用户（以下简称“您”）提供“叁零荟”平台的商品和/或服务。我们非常注重保护您的个人信息及隐私，我们深知个人信息对您的重要性，并将按照法律法规要求和业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。</p>
		
		<p>我们希望通过《叁零荟隐私政策》（以下简称“本政策”）向您清晰地介绍当您使用”叁零荟”平台商品和/或服务时，我们如何处理您的个人信息，以及我们为您提供的访问、注销账号等实现您用户权利和保护这些信息的方式。</p>
		
		<p>本政策将帮助您了解以下内容：</p>
		
		<p>一、我们如何收集和使用您的个人信息</p>
		
		<p>二、我们如何使用Cookie和同类技术</p>
		
		<p>三、我们如何共享、转让、公开您的个人信息</p>
		
		<p>四、我们如何保存和保护您的个人信息</p>
		
		<p>五、您如何管理您的个人信息</p>
		
		<p>六、未成年人的个人信息保护</p>
		
		<p>七、通知和修订</p>
		
		<p>八、如何联系我们</p>
		
		<p>九、附则</p>
		
		<p>十、关键词定义</p>
		
		<p>【特别提示】请您在使用我们提供的各项“叁零荟”平台商品和/或服务前，仔细阅读并充分理解本政策（对所有重点内容，我们采用“加粗”的书写方式进行提示；同时，对敏感个人信息，我们用“加粗斜体”的书写方式进行特别提醒，希望您在阅读时特别关注并作出相应选择）。如对本政策有任何疑问，您可以通过本政策“如何联系我们”中载明的方式与我们联系。</p>
		
		<p>请您注意，本政策不适用于您通过”叁零荟”平台中的链接跳转到第三方平台，由第三方平台（包括但不限于您不在”叁零荟”平台发生的交易相对方、任何第三方网站以及第三方服务提供者）向您提供商品和/或服务的情况。具体规定请参照该第三方平台的隐私政策或类似声明。</p>
		
		<p class="strong">一、我们如何收集和使用您的个人信息</p>
		
		<p>我们会遵循正当、合法、必要的原则，出于本政策所述的下列目的收集和使用您的个人信息。我们不会收集与向您提供商品和/或服务无关的个人信息，也不会因您不同意收集非必要个人信息，而拒绝您使用”叁零荟”平台的基本业务功能。如果我们将您的个人信息用于本政策未载明的其它用途，或基于其他特定目的而收集、使用您的个人信息，我们将以合理的方式告知您，并在收集、使用前再次征得您的同意。</p>
		<p>（一）基本业务功能下收集、使用您的个人信息</p>
		“叁零荟”平台的基本业务功能为「浏览商品」功能。为实现”叁零荟”平台的基本业务功能，我们需要向您收集以下个人信息，若您拒绝提供，”叁零荟”平台将无法为您提供相应的商品和/或服务。</p>
		<p>（1）注册和/或登录</p>
		<p>我们通过”叁零荟”平台账号为您提供商品和/或服务，当您注册成为我们的用户时，您需要向我们提供手机号码或微信号以创建”叁零荟”平台账号。我们可能会发送短信验证码，以验证您所注册的手机号码是否有效。您在注册本平台用户时提供的用于登录的账号，也将作为日后与您联系时（若需）的通讯方式。如果您已有”叁零荟”平台账号，可在”叁零荟”平台上通过手机号码或微信号直接登录。</p>
		<p>（2）浏览</p>
		<p>当完成”叁零荟”平台账号注册后，您可以通过该账号登录本平台，浏览我们在”叁零荟”平台上发布的商品信息。为了【向您展示商品和/或服务信息】，我们会收集您的【设备、日志、账号】信息。</p>
		<p>提醒您注意，当您不同意本政策时，我们仅会向您提供浏览”叁零荟”平台上商品信息的功能，但此时我们不会收集您的任何个人信息。</p>
		<p>（二）扩展业务功能下收集、使用您的个人信息</p>
		<p>为了给您提供购买商品和/或服务的功能，在您使用此类功能时，我们会基于特定目的收集您的个人信息。若您拒绝提供，我们将无法向您提供相应的商品和/或服务，但不影响您使用前述基本业务功能。</p>
		<p>（1）实名认证信息</p>
		<p>为向您提供公平的购买资格与价格，您需要在线完成向我们的叁零荟或其线下门店进行购买，并向我们提供您的实名认证信息（包括姓名、身份证号码）以防止重复购买行为。身份证号码属于敏感个人信息，在收集此类信息前我们会取得您的单独同意。如果您拒绝提供，您将可能无法进行购买或支付，但不影响”叁零荟”平台基本业务功能的正常使用。</p>
		<p>（2）位置信息</p>
		<p>为提高您购买商品的便捷性，您需要在指定时间内在”叁零荟”平台进行购买，我们会基于您的单独同意方才开启终端设备的位置权限并获取您的位置信息。此外，您也可以手动选择您所在的具体城市，以便我们为您展示您所在城市网点内的叁零荟或其线下门店以进行购买。</p>
		<p>（3）实人认证信息</p>
		<p>当您获得购买机会后，您可以选择在”叁零荟”平台上进行线上支付或者线下门店付款。如果您选择前者，由与叁零荟合作的第三方支付机构提供订单支付服务。同时，为了交易安全、防止作弊和刷单的风控要求，您需要首先完成实人认证的流程。以下方式任选其一：</p>
		<p>1.当您选择身份证认证的，您需要向”叁零荟”平台填写身份证号，或者上传您的身份证正反面照片以进行核验；</p>
		<p>2.当您选择人脸识别认证的，您需要通过”叁零荟”平台对人脸的活体摄录（包括面部特征数据）以完成核验。</p>
		<p>实人认证是为了证明您为”叁零荟”平台账号真实所有者身份。为了保障您的个人信息安全，我们不会采集并存储您的面部特征数据和身份证正反面照片，通过您终端设备摄录的面部特征数据和身份证正反面照片在生成DID数字身份证后仍然加密存储于您自己的终端设备中。</p>
		<p>（4）订单信息和支付信息</p>
		<p>当您购买成功后，您可以选择在”叁零荟”平台上进行线上支付或者线下门店付款。为实现线上支付功能，我们将使用与您订单相关的信息（包括商品信息、下单时间、订单金额）与支付信息（包括支付时间、支付金额、支付方式）。如果您希望到门店付款的，则需要提供您的订单信息。关于与第三方支付机构共享您个人信息的情况，详见”叁零荟”平台另行发布的《第三方合作伙伴收集个人信息情况列表》。</p>
		<p>（5）收货信息</p>
		<p>您在本平台购买的部分商品可以选择物流配送或门店自提两种收货方式。</p>
		<p>在当您选择物流配送时，您需要向我们提供接收商品的收货地址、收货人姓名、收货人手机号码，以保证您购买的商品能够送达。如您拒绝向我们提供上述信息的，我们将无法为您提供配送服务，但您可以选择线下门店自提方式。</p>
		<p>请您知悉并注意，我们将委托物流配送公司在您签署前先【请您出示您的身份证件、手机号码】以核验您的身份再向您交付所购买的酒品。</p>
		<p>如您选择门店自提，您需到您所选择的叁零荟线下授权门店自提所购买的产品。叁零荟自营门店或者授权经销商会使用您的本人有效身份证及订单信息用以核验您的身份、为您交付所购买的酒品和收取货款。</p>
		<p>（6）发票信息</p>
		<p>若您需要开具发票，您需要在发票开具页面填写开票信息，向我们提供发票抬头、纳税人识别号、收票人手机号、收票邮箱等开票必要信息。如您未提供前述信息，我们将无法为您开具发票。</p>
		<p>（7）商品评分</p>
		<p>您在”叁零荟”平台上对所购买的酒品进行评分时，我们将收集使用您主动发布的评分信息。</p>
		<p>（8）客服和/或客诉</p>
		<p>为了查实您反馈的情况以及改善我们的服务质量，当您与我们的客服取得联系时，我们的系统可能会记录您与客服之间的通讯记录，以及使用您的账号信息以便核验身份。当您需要我们提供与您订单相关的客户服务时，我们可能会查询您的相关订单信息以便给予您适当的帮助和处理。当您需要客服协助您修改有关信息（包括联系方式）时，您可能还需要提供上述信息外的其他信息以便完成修改。</p>
		<p>（9）商业广告</p>
		<p>我们可能会使用您在注册时向我们提供的手机号码，通过短信的形式向您推送普通的商业广告，如果您不想接受我们给您发送的商业广告，您可通过短信提示回复退订或我们提供的其他方式进行退订或关闭。</p>
		<p>（10）安全管理</p>
		<p>为了满足法律法规及提供商品和/或服务的基本要求，保障您的账号安全、交易安全与系统运行安全，我们会收集您在使用我们商品和/或服务过程中产生的相关信息，包括：</p>
		<p>剪切板信息：为便于您查询、复制个人信息，在您通过手机验证码访问个人信息时，我们会需要访问您的剪切板，以便为您提供更好的功能体验，并且不会存储您的剪切板信息。</p>
		<p>日志信息：当您使用我们提供的商品和/或服务时，经您授权我们会获取您使用我们服务的详细情况，并作为有关的网络日志保存，包括您的浏览和操作记录、IP地址、电信运营商、使用的语言、访问日期和时间。</p>
		<p>设备信息：在您使用我们的商品和/或服务过程中，经您授权我们会获取您的设备信息，包括设备型号、操作系统版本、设备设置、设备标识符（MAC地址和/或IMEI／Android ID／IDFA／OpenUDID／GUID／SIM卡IMSI/SIM卡ICCID 信息）、移动应用列表等软硬件及设备环境信息、设备所在位置相关信息（包括IP地址、GPS位置以及WLAN接入点、蓝牙、基站传感器信息）和设备权限信息（包括存储权限），以确保设备操作环境的安全以及提供”叁零荟”平台商品和/或服务所必需，防止恶意程序和反作弊。</p>
		<p>为提高您使用我们提供的商品和/或服务的安全性，更好地预防钓鱼网站、计算机病毒等安全风险，保护您或其他用户或公众的人身财产安全，更准确地识别违反法律法规或”叁零荟”平台相关协议规则的情况，我们可能会使用或整合您的账号信息、交易信息、设备信息、网络日志及其他第三方依法共享的信息来综合判断您账号及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。</p>
		<p>（三）我们从第三方获取的您的个人信息</p>
		<p>为完成身份核验，保障账号安全，确认交易状态及为您提供售后与争议解决服务目的，经您单独同意后我们可能会从第三方获取您的个人信息（通过您选择的交易对象和交易直接涉及的第三方信息系统、支付机构等收集与交易进度相关的您的交易商品、支付时间、支付金额、支付订单信息），以便于我们处理您的订单并保证服务的顺利完成，或者更好地预防诈骗、刷单等恶意行为。我们会依据法律法规的要求以及与第三方的约定，经您授权后从叁零荟的关联方、合作伙伴等处获得您的有关信息，并对其信息来源的合法性进行确认后使用您的这些信息。</p>
		<p>请您知悉如果我们将上述信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会单独征求您的授权同意。
		<p>（四）”叁零荟”平台所调用的系统权限</p>
		<p>如上所述，为了向您提供”叁零荟”平台商品和/或服务，我们可能需要使用您设备中的系统权限来收集相关个人信息。尽管我们在前述已经有所涉及，本节将对我们所使用的所有系统权限进行归纳整理，以方便向您更加系统、完整、透明地呈现。您可以选择是否授权我们使用您设备的相关权限。如果您不授权我们使用相关权限，可能会导致部分”叁零荟”平台商品和/或服务无法使用，但是不影响您使用”叁零荟”平台进行浏览商品/服务的基本功能。</p>
		<p>位置权限：当您通过系统授权开启移动设备的定位功能时，我们会收集和使用您的位置信息，以便为您展示您所在城市网点内的叁零荟或其线下门店以进行购买。经您的单独同意，我们会使用您的位置信息。您可以在移动设备的系统中关闭定位服务停止我们对您所在位置信息的收集，但可能因此无法使用我们基于地理位置为您提供的前述服务，或者无法达到相关服务的预期效果。</p>
		<p>摄像头权限：为了您能正常使用”叁零荟”平台实人认证、扫一扫功能，基于您的单独同意，我们将使用终端设备系统中的摄像头权限。您也可以撤回授权，在系统中关闭该权限，但可能导致无法为你提供相关服务，或者无法达到相关服务的预期效果。请您知晓，即使您已同意开启摄像头权限，我们也仅会在您主动点击客户端内相机图标或确认实人认证时通过摄像头获取相关的影像信息。</p>
		<p>NFC权限：为了您能正常使用NFC功能进行溯源服务，基于您的单独同意，我们将使用终端设备系统中的NFC权限。您也可以撤回授权，在系统中关闭该权限，但可能导致无法为你提供相关服务，或者无法达到相关服务的预期效果。请您知晓，即使您已同意开启NFC权限，我们也仅会在您使用NFC溯源功能时主动点击“立即感应”来获取相关的NFC信息。</p>
		<p>存储权限：用于缓存您在使用”叁零荟”平台过程中产生的图像、视频，您授权我们使用终端设备系统中的存储权限。您可以在系统中关闭该权限，但可能导致无法为您提供前述服务，或者无法达到相关服务的预期效果。</p>
		<p>通知权限：为了给您提供更好服务，需要您通过系统授权开启消息通知功能，便于我们及时通知您的订单动态、物流动态，可以及时收到平台的活动通知。</p>
		<p>网络访问授权：为了保证您可以在”叁零荟”平台正常登录、浏览、购买 、下单等，需要您授权我们网络访问权限，以实现应用程序联网。</p>
		<p>相册权限：为了您能正常使用”叁零荟”平台扫描二维码、上传图片、保存图片等服务，基于您的单独同意，我们将使用终端设备系统中的相册权限。您也可以撤回授权，在系统中关闭该权限，但可能导致无法为你提供相关服务，或者无法达到相关服务的预期效果。请您知晓，即使您已同意开启相册权限，我们也仅会在您主动点击客户端内相册图标时通过相册服务获取相关的图片信息。</p>
		<p>（五）其他合法性事由</p>
		<p>请您知悉，根据相关法律法规的规定，在以下情形中，我们可以在不征得您的授权同意的情况下收集、使用一些必要的个人信息：</p>
		<p>1、为订立、履行个人作为一方当事人的合同所必需；</p>
		<p>2、为履行法定职责或者法定义务所必需；</p>
		<p>3、为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；</p>
		<p>4、依照《中华人民共和国个人信息保护法》的规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；</p>
		<p>5、法律、行政法规规定的其他情形。</p>
		
		<p class="strong">二、我们如何使用Cookie和同类技术</p>
		
		<p>（一）Cookie的使用</p>
		<p>为确保”叁零荟”平台正常高效运转，向您提供简单易操作的网络体验，我们会在您的设备终端和/或系统上存储名为Cookie的小数据文件。我们使用Cookie或同类技术，可能用于以下用途：1、记住您的身份。例如：Cookie或同类技术有助于我们辨认您作为我们注册用户的身份，或保存您向我们提供的其他信息，或协助判断您的登录状态是否正常、账号是否存在异常情况；2、分析您使用我们商品和/或服务的情况。我们可利用Cookie或同类技术来了解您使用”叁零荟”平台商品和/或服务的什么功能、或哪些商品和/或服务最受欢迎。我们不会将Cookie用于本政策所述目的之外的任何用途。</p>
		<p>您可根据自己的偏好管理或删除Cookie。您可以清除移动设备上保存的所有Cookie，您有权接受或拒绝Cookie。您通常可根据自己的需要来修改浏览器的设置以拒绝Cookie；另外，您也可以清除软件内保存的所有Cookie。但您可能因此无法完全体验我们某些便捷性和安全性的商品和/或服务功能。</p>
		<p>请您知悉，倘若您拒绝使用或清除已有的追踪技术，则需要在每一次访问时亲自更改用户设置，并且，我们可能无法向您提供更加优质的使用体验，部分商品和/或服务亦可能会无法正常运作。</p>
		
		<p class="strong">三、我们如何委托处理、共享、转让、公开您的个人信息</p>
		
		<p>（一）委托处理</p>
		<p>为了向您提供更完善、更优质的商品和/或服务，某些功能可能由我们的技术服务合作方提供，我们可能会委托合作方处理您的某些个人信息。对于受我们委托处理您个人信息的公司、组织和个人，我们会与其签署严格的保密协定，明确数据处理事项、处理期限、处理性质、处理目的以及双方责任等，要求合作方仅按照我们的要求、本政策的规定以及其他任何相关的保密和安全措施来处理您的个人信息。我们会委托提供技术服务的第三方SDK合作伙伴处理您的个人信息。关于合作伙伴的身份、收集个人信息的目的、方式、范围等情况，请您详细参见本政策附件《第三方合作伙伴收集个人信息情况列表》。如果您拒绝我们的合作伙伴在提供商品和/或服务时收集为提供商品和/或服务所必需的个人信息，将可能导致您在使用”叁零荟”平台商品和/或服务的过程中无法享受该合作伙伴提供的商品和/或服务。</p>
		<p>（二）共享</p>
		<p>我们不会与叁零荟以外的任何公司、组织和个人共享您的个人信息。在以下情形下，我们将向其他个人信息处理者提供您的个人信息，向您告知接收方的名称或者姓名、联系方式、处理目的、处理方式和个人信息的种类，并取得您的单独同意。</p>
		<p>1、与关联方共享：为便于我们与关联公司共同向您提供部分服务，我们可能会将您的个人信息向我们的关联方提供，但我们只会共享必要的个人信息，且这种共享受本政策所声明目的的约束。如果我们共享您的个人敏感信息或者关联方改变个人信息的使用目的，将再次征求您的单独同意。</p>
		<p>2、与合作伙伴进行必要信息的共享：为了向您提供更完善、优质的商品和/或服务，某些商品和/或服务将由合作方提供。因此，基于您的单独同意，我们可能会与合作方共享您的某些个人信息。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享为您提供商品和/或服务所必要的个人信息。该等共享的合作方为数据处理者，其将以自己的名义获得您的同意以及处理您的个人信息。合作方可能有其独立的隐私政策和用户服务协议，我们建议您阅读并遵守合作方的用户服务协议及隐私政策。基于您的单独同意，我们可能会向业务合作伙伴共享为您提供商品和/或服务所必要的订单信息、账号信息、支付信息。我们的业务合作伙伴包括以下类型：</p>
		<p>（1）支付服务供应商：为向您提供支付服务，我们将向支付服务供应商共享您的订单编号、订单金额、商品类型。</p>
		<p>（2）实名认证服务提供商：为向您提供实名认证服务，我们将向实名认证服务提供商、叁零荟授权经销商共享您的姓名、身份证号码。</p>
		<p>（3）人脸识别服务提供商：为完成人脸识别，我们将向人脸识别服务提供商共享您的人脸识别特征数据。</p>
		<p>（4）物流配送公司：为向您提供物流配送服务，我们将向第三方物流配送公司共享您的订单信息（包括商品信息、下单时间）以及收货信息（包括接收货物的收货地址、收货人姓名、收货人手机号码）。</p>
		<p>（5）叁零荟授权线下门店：为向您提供自提服务，我们将向叁零荟授权线下门店共享您的订单信息、收件人姓名、联系方式和收件地址。为向您开具发票，我们将向叁零荟授权线下门店共享您的发票抬头、纳税人识别号等必要信息，由您在开票页面自行填写。</p>
		<p>关于”叁零荟”平台商品和/或服务中接入的第三方SDK共享个人信息的，详情见本政策附件《第三方合作伙伴收集个人信息情况列表》。</p>
		<p>如果您拒绝我们的合作方在提供商品和/或服务时收集为提供商品和/或服务所必需的个人信息，将可能导致您无法使用”叁零荟”平台商品和/或服务来享受该合作方提供的商品和/或服务。</p>
		<p>请您知悉，即使已经取得您的单独同意，我们也仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并对我们仅为实现本政策中声明的目的与之共享个人信息的公司、组织和个人，我们会与其签署严格的信息保护和保密协定，要求他们遵守协议并采取相关的安全措施来保护您的个人信息且无权将共享的个人信息用于任何其他用途。</p>
		<p>（三）转让</p>
		<p>原则上我们不会将您的个人信息控制权向其他公司、组织或个人进行转移。如果发生合并、收购或破产清算，将涉及到个人信息转让，此种情况下我们，我们将向您告知接收方的名称或者姓名和联系方式，并会要求新的持有您个人信息的公司、组织继续受本政策的约束。如果本政策中约定的个人信息的收集、处理方式发生任何改变，该公司、组织将重新向您征求授权同意。</p>
		<p>（四）公开</p>
		<p>除非获取您的单独明确同意，我们不会公开您的个人信息。</p>
		<p>请注意，当您在”叁零荟”平台购买商品和/或使用服务时自愿发布甚至公开分享的评分信息以及去标识化处理后的购买者名单会被公开。请您在使用”叁零荟”平台商品和/或服务时更加谨慎地考虑，以确定是否要发布甚至公开分享相关信息。</p>
		
		<p class="strong">四、我们如何保存和保护您的个人信息</p>
		
		<p>（一）个人信息的存储 </p>
		<p>1、保存期限</p>
		<p>我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非法律有强制的留存要求，例如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。</p>
		<p>我们判断个人信息的存储期限主要依据以下标准:</p>
		<p>1、完成与您相关的交易目的、维护相应交易及业务记录，以应对您可能的查询或投诉;</p>
		<p>2、保证我们为您提供服务的安全和质量;</p>
		<p>3、您是否同意更长的留存期间;</p>
		<p>4、根据诉讼时效的相关需要;</p>
		<p>5、是否存在关于保留期限的其他特别约定或法律法规规定。</p>
		<p>在超出保留期间后，我们会根据适用法律的要求删除或匿名化处理您的个人信息。</p>
		<p>在您主动注销账号时，我们将根据法律法规的要求尽快删除您的个人信息或作匿名化处理。</p>
		<p>2、保存地域</p>
		<p>我们在中华人民共和国境内收集和产生的个人信息，将存储在中国境内，我们不会将您的个人信息传输至境外。</p>
		<p>3、终止运营</p>
		<p>如果发生终止运营等情形，我们将会至少提前30天通知您，并在终止运营后对您的个人信息进行删除或匿名化处理。</p>
		<p>（二）个人信息的保护措施</p>
		<p>1、数据安全措施</p>
		<p>为保障您的信息安全，我们努力采取各种符合业界标准的物理、电子和管理方面的安全措施来保护您的个人信息，建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。我们通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制，防止您的个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们的专业安全团队对个人信息会进行安全加固（包括敏感信息报备、敏感信息加密存储、访问权限控制等）。我们会使用不低于我们对自身用户个人信息同等的保护手段与措施对间接获取的个人信息进行保护。例如使用加密技术确保数据的保密性；使用受信赖的保护机制防止数据遭到恶意攻击；部署访问控制机制，确保只有授权人员才可访问个人信息；以及举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</p>
		<p>2、安全认证</p>
		<p>我们与监管机构、第三方测评机构建立了良好的协调沟通机制，及时抵御并处置各类信息安全威胁，为您的信息安全提供全方位保障。</p>
		<p>3、合作协议条款保证</p>
		<p>在我们从第三方间接收集您的个人信息前，我们会明确以书面形式（如合作协议、承诺书）要求该第三方在已经取得您明示同意后收集以及处理您的个人信息，并要求第三方对个人信息来源的合法性和合规性作出承诺，如第三方有违反行为的，我们会明确要求对方承担相应的法律责任。</p>
		<p>在我们向合作伙伴共享您的个人信息前，我们取得您的单独同意并严格要求合作伙伴承担信息保护义务与责任。为此，我们会在合作之前要求合作伙伴签署数据处理协议或在双方签署的合作协议中列明有关数据保护的条款。协议严格约定合作伙伴对用户信息的保密义务，包括信息的保管、使用和流转等均应满足我们的管控要求，接受我们的审核、监督和审计，一旦有任何违反，我们会要求对方承担相应的法律责任。</p>
		<p>4、安全事件</p>
		<p>为应对个人信息泄露、损毁和丢失等可能出现的风险，我们制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。我们也为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。</p>
		<p>在不幸发生个人信息安全事件后，我们会立即成立专项应急小组，启动应急预案，防止安全事件扩大，并按照法律法规的要求及时向您告知:安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</p>
		<p>请您知悉并理解，互联网并非绝对安全的环境，我们强烈建议协助我们保证您的账号安全。如您发现自己的个人信息泄密，尤其是您的账号或密码发生泄露，请您立即根据本政策中提供的联系方式联络我们，以便我们采取相应措施。</p>
		
		<p class="strong">五、您如何管理您的个人信息</p>
		
		<p>您对您的个人信息享有以下权利:</p>
		<p>（一）访问或更正您的个人信息</p>
		<p>您有权随时登录并访问您的账号，查看您的个人信息。如您发现我们收集、存储、使用、公开的您的个人信息有错误的，或者在法律法规规定的其他情况下，您可以更正您的个人信息。您可以通过以下方式管理您的信息:</p>
		<p>账号信息——您可以访问您账号中的头像等，您可以通过访问”叁零荟”平台或在“我的”中执行此类操作。</p>
		<p>订单信息——您可以通过访问“我的”-“我的订单”页面查看您全部订单待付款、待提货、待评价的订单。</p>
		<p>收货信息——您可以通过访问“我的”-“设置” – “地址管理”进行访问或更正您的收货信息（包括接收货物的收货地址、收货人姓名、收货人手机号码）您也可以将最常用的收货地址设置为默认地址，如此，若您下次购买商品时未更改收货地址，您的商品会配送到该默认地址。</p>
		<p>发票信息——您可以通过访问“我的”-“设置”–“发票信息”，在“发票信息”页面页面上进行填写或查看发票信息。</p>
		<p>评分信息——您可以通过访问“我的”-“全部订单”页面查看您已经完成的商品评分。</p>
		<p>如果您无法通过上述链接管理这些个人信息，您可以通过本政策第八条所述方式联系我们或者按照页面提示进行联系。我们将在15个工作日内完成用户身份核查和处理。</p>
		<p>（二）删除您的个人信息</p>
		<p>在以下情形中，您可以向我们提出删除您个人信息的请求，但已进行个人信息匿名化处理或法律法规另有规定的除外：</p>
		<p>我们违反法律、行政法规的规定或与您的约定，处理您的个人信息的；</p>
		<p>处理目的已实现、无法实现或者为实现处理目的不再必要；</p>
		<p>您通过注销等方式终止使用”叁零荟”平台商品和/或服务的，或保存期限已届满；</p>
		<p>您撤回授权；</p>
		<p>法律法规等规定的其他情形。</p>
		<p>如您希望删除您的其他个人信息，或在行使上述权利过程中遇到困难，您可通过本政策第八条所述方式联系我们，我们将在15个工作日内完成用户身份核查和处理，但法律法规另有规定的或本声明另有约定的除外。当我们决定响应您的删除请求时，我们将尽快从服务器中删除您的信息，但法律法规另有规定的除外。请您知悉，我们可能不会将您需要删除的信息立即从备份系统中删除，但会在备份系统更新时及时删除。</p>
		<p>（三）更改您授权同意的范围或撤回授权</p>
		<p>请您理解，每个业务功能往往需要开启部分权限并收集必要的个人信息才能得以实现，对于您个人信息的收集、使用以及设备权限的管理。您可以在操作系统中关闭设备功能等方式改变同意范围或撤回您的授权。</p>
		<p>如果您希望更改其他授权范围或撤回其他处理活动的授权，您可以通过本政策第八条所述方式联系我们或者按照页面提示进行联系。我们将在15个工作日内完成用户身份核查和处理。请您理解，当您执行上述操作后，我们将无法继续为您提供撤回同意或授权所对应的商品和/或服务，但不会影响此前基于您的授权而开展的个人信息处理。</p>
		<p>（四）注销账号</p>
		<p>您有权注销您的”叁零荟”平台账号，您可以通过【我的】-【设置】-【账号管理】-【注销】平台注销功能进行注销协助您进行注销。您注销成功后，我们将根据法律法规的要求尽快删除您的个人信息或作匿名化处理。</p>
		<p>如果您无法通过上述方式访问、更正或删除您的个人信息以及注销账号，或者如果您认为”叁零荟”平台存在违反法律的规定收集、使用您个人信息的情形，您可以通过本政策第八条所述方式联系我们提供的其他方式与我们取得联系。我们将在15个工作日内完成用户身份核查和处理。</p>
		<p>（五）获取您的个人信息副本</p>
		<p>您有权获取您的个人信息副本。如您需要获取我们收集的您的个人信息副本，您可通过【本政策第八条所述方式联系我们】，我们会对您进行身份核验后，在符合相关法律规定且技术可行的前提下，将个人信息副本通过邮箱交给您。如您请求将您的个人信息转移至您指定的个人信息处理者，符合国家网信部门规定条件的，我们将提供转移的途径。</p>
		<p>（六）限制处理您的个人信息</p>
		<p>请您知悉，为了您能正常使用”叁零荟”平台，您需要向我们提供一些基本的个人信息。如果您希望限制我们对您个人信息的处理或者不想接受我们发送给您的商业广告或营销信息，您可通过本政策第八条所述方式联系我们。我们将在15个工作日内完成用户身份核查和处理。</p>
		<p>（七）响应您的请求</p>
		<p>为实现本政策下的用户权利，或如您对您的数据主体权利存在任何疑问、投诉、意见或建议的，您可以通过本政策提供的联系方式与我们联系。为了保障安全，我们可能需要您提供相应方式证明您的身份和请求的正当性，我们将在收到您反馈起15个工作日内验证您的身份和进行处理。</p>
		<p>对于您合理的请求，我们原则上不收取费用，但对无端重复、超出合理限度的请求、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。</p>
		<p>（八）对去世用户的个人信息保护</p>
		<p>我们将根据《个人信息保护法》的相关规定制定死者个人信息保护制度。如”叁零荟”平台用户去世后，其近亲属为了自身的合法、正当利益，可以通过本隐私政策第八条“如何联系我们”中公示的联系方式，对去世用户的相关个人信息行使查阅、复制、更正、删除等权利，但是去世用户生前另有安排的除外。</p>
		<p>您理解并确认，为了充分保护去世用户的个人信息权益，申请行使本条权利的去世用户近亲属需要根据”叁零荟”平台指定流程或客服提示，提交去世用户的身份证明文件、死亡证明文件、申请人的身份证明文件、申请人与去世用户的亲属关系证明文件，并提供申请行使的权利种类、目的。</p>
		
		<p class="strong">六、未成年人的个人信息保护</p>
		
		<p>“叁零荟”平台商品和/或服务面向具有完全民事行为能力的成年人。根据《未成年人保护法》规定，禁止向未满十八周岁的未成年人出售烟酒。请注意，未满十八周岁的未成年人不应创建”叁零荟”平台账号或者使用”叁零荟”平台商品和/或服务。如果您是未成年人，请不要以任何方式使用或访问”叁零荟”平台并购买商品和/或使用服务。如果您发现我们无意收集了未成年人的个人信息，请您立即通知我们，我们会尽快设法删除相关数据。</p>
		
		<p class="strong">七、通知和修订</p>
		
		<p>为给您提供更好的商品和/或服务，我们的业务将不时变化，本政策也将随之调整。未经您明确同意，我们不会削减您依据本政策所应享有的权利。我们会通过在”叁零荟”平台上发出更新版本或以其他方式提醒您相关内容的更新，也请您访问我们以便及时了解最新的隐私政策。</p>
		<p>对于重大及实质性修订，我们会提供显著的通知。我们将尽可能通过可行的渠道和方法，将修订通知于您。请您访问”叁零荟”平台商品和/或服务查询本政策的最新版本。</p>
		<p>重大、实质性变更的情形包括但不限于以下情形：</p>
		<p>（1）我们的商品和/或服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</p>
		<p>（2）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</p>
		<p>（3）个人信息共享、转让或公开的主要对象发生变化；</p>
		<p>（4）您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
		<p>（5）我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</p>
		<p>（6）个人信息安全影响评估报告表明存在高风险时。</p>
		
		<p class="strong">八、如何联系我们</p>
		
		<p>如果您对于我们的个人信息处理行为存在任何投诉举报需求，您可以通过以下方式与我们取得联系：（1）通过隐私保护专用邮箱slh@sangeling.com 联系我们的个人信息保护负责人，（2）本平台客服电话：18916620368/18815070555。请您将您的问题进行充分描述，我们将在15个工作日内完成用户身份核查和处理。</p>
		
		<p class="strong">九、附则</p>
		
		<p>本政策的解释及争议解决均应适用中华人民共和国法律。与本政策相关的任何纠纷，双方应经友好协商解决；若不能协商解决，您在此同意将争议提交至叁零荟所在地人民法院提起诉讼。</p>
		<p>如果有管辖权的任何法院裁定或判决本政策的任何条款无效，则该条款将从本政策中移除，但该条款的无效不影响本政策其余条款的效力。本政策的其余条款将继续执行。</p>
		<p>本政策的标题仅为方便阅读而设计，不影响本政策任何条款的含义或解释。</p>
		
		<p class="strong">十、关键词定义</p>
		
		<p>（一）个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。本隐私政策中可能涉及到的个人信息包括但不限于：个人基本信息（包括姓名、电话号码）；个人身份信息（包括身份证）；网络身份标识信息（包括系统账号、IP地址）；个人上网记录（包括浏览记录、点击记录等）；个人设备信息（包括唯一设备识别码等描述个人常用设备基本情况的信息）。</p>
		<p>（二）个人敏感信息：是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。本隐私政策中可能涉及到的个人敏感信息包括：个人身份信息（包括身份证）；个人生物识别特征（包括面部识别特征）；个人位置信息（定位信息）。</p>
		
	</div>
</div>
</template>

<script>
	
	export default {
	  name: 'index',
	  components: {
	  },
	  data() {
		return {
		  title:"三个零",
		}
	  },
	  created() {
		
	  },
	  methods: {

	  } 
	}
</script>

<style>
	p{
		margin: 5px 0;
		line-height: 1.5;
	}
	.container {
	  padding: 10px;
	}
	.strong{
		font-weight: bold;
	}
	.item{
		margin-top: 10px;
	}
</style>
