import { render, staticRenderFns } from "./privacy.vue?vue&type=template&id=2ecaaa98&"
import script from "./privacy.vue?vue&type=script&lang=js&"
export * from "./privacy.vue?vue&type=script&lang=js&"
import style0 from "./privacy.vue?vue&type=style&index=0&id=2ecaaa98&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports